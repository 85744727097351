import React from 'react';
import Timeline, {
  TimelineHeaders,
  DateHeader,
  TimelineMarkers,
  CustomMarker
} from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css';
import moment from "moment";

const TimelineComponent = ({ timeInterval, routesList, selectedRoutesDate, routeStartTime, routeDCTime }) => {
  const groups = routesList.length > 0 && routesList.map(item => ({ id: item.routeId, title: item.routeId }))

  const convertEndTime = (time) => {
    const date = new Date(time);
    const newTime = date.setMinutes(date.getMinutes() + 30);
    const dateinmilliseconds = moment(newTime);
    return dateinmilliseconds.format('YYYY-MM-DD HH:mm:ss');
  }

  const convertStartTime = (time) => {
    const date = new Date(time);
    const newTime = date.setMinutes(date.getMinutes() - 30);
    const dateinmilliseconds = moment(newTime);
    return dateinmilliseconds.format('YYYY-MM-DD HH:mm:ss');
  }
  const items = [];
  if (routesList.length > 0) {
    const startTimes = routesList.length > 0 && routesList.map(item => ({ routeId: item.routeId, stopsData: `${item.stops.length} ${item.stops.length === 1 ? 'Stop' : 'Stops'}`, type: "startSlot", allignData: "startendslot", timeDuration: moment(item.endTime).diff(moment(item.startTime), 'hours'), title: <div>Start<div>{moment(item.startTime).format('hh:mm A')}</div></div>, className: "slotStart", endTime: item.startTime, startTime: convertStartTime(item.startTime) }));
    const endTimes = routesList.length > 0 && routesList.map(item => ({ routeId: item.routeId, className: "slotEnd", type: "endSlot", allignData: "startendslot", routeServiceTimeStatus: item.routeServiceTimeStatus, title: <div>End<div>{moment(item.endTime).format('hh:mm A')}</div></div>, startTime: item.endTime, endTime: convertEndTime(item.endTime) }));
    const mergedTimes = startTimes.concat(endTimes);
    let itemId = 1;
    routesList.length > 0 && routesList.forEach(route => {
      route.stops.forEach((stop, index) => {
        items.push({
          id: itemId++,
          group: route.routeId,
          title: stop.caseCount,
          serviceTimeStatus: stop.serviceTimeStatus,
          description: stop.serviceTimeStatus === "Delayed" ? `(${stop.serviceTimeStatusinMins} mins)` : "",
          start_time: moment(stop.stopStartTime),
          end_time: stop.stopStatus === "In Progress" ? moment(convertEndTime(stop.stopStartTime)) : moment(stop.stopEndTime),
          className: stop.stopStatus === "In Progress" ? "inprogress" : stop.serviceTimeStatus === "Delayed" ? "delay" : stop.serviceTimeStatus === "Ahead" ? "ahead" : "ontime"
        });
      });
    });

    mergedTimes.forEach(route => {
      items.push({
        id: itemId++,
        group: route.routeId,
        title: route.title,
        allignData: route.allignData,
        description: route.type === "startSlot" ? `${route.stopsData}` : "",
        start_time: moment(route.startTime),
        end_time: moment(route.endTime),
        className: route.className === "slotStart" ? "slotStart" : route.routeServiceTimeStatus === "Delayed" ? "slotEndDelay" : route.routeServiceTimeStatus === "Ahead" ? "slotEndAhead" : "slotEndOnTime"
      });
    });
  }

  const defaultTimeStart = routeStartTime ? routeStartTime : moment(selectedRoutesDate).startOf('day');
  const defaultTimeEnd = moment(selectedRoutesDate).endOf('day');

  // Set the time steps for 30-minute intervals
  const timeSteps = {
    second: 0,
    minute: timeInterval,
    hour: 1,
    day: 1,
    month: 0,
    year: 1,
  };

  const date = new Date(routeDCTime);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const now = new Date();
  const markerTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes, 0);

  return (
    <div style={{ display: "flex", maxWidth: window.innerWidth > 2000 ? "1400px" : "1190px", overflowX: "auto" }}>
      <Timeline
        groups={groups}
        items={items}
        itemRenderer={({ item, getItemProps }) => {
          const backgroundColor = 'lightblue';
          const borderColor = 'white'; // Border color between events
          const style = {
            backgroundColor,
            color: 'black',
            border: `1px solid ${borderColor}`, // Border between events
            borderBottom: 'none', // Remove bottom border on last event
            borderRadius: '4px',
            cursor: "auto",
            pointerEvents: "none"
          };
          return (
            <div
              {...getItemProps({
                item,
                style
              })}
            >
              <div className="custom-tooltip">
                <div style={{ fontSize: "10px", display: "flex", justifyContent: "center", whiteSpace: "nowrap", lineHeight: item.allignData === "startendslot" ? "20px" : "40px" }}>{item.title}</div>
                <div style={{ fontSize: "10px", display: "flex", justifyContent: "center", marginTop: item.allignData === "startendslot" ? "-10px" : "-10px", whiteSpace: "nowrap", color: item.serviceTimeStatus === "Delayed" ? "#C51F1F" : "black" }}>{item.description}</div> {/* Display description */}
              </div>
            </div>
          );
        }}
        defaultTimeStart={defaultTimeStart}
        defaultTimeEnd={defaultTimeEnd}
        canSelect={false}
        timeSteps={timeSteps}
        lineHeight={60} // Adjust the lineHeight to add space between timeline columns
        sidebarWidth={0} // Adjust the sidebarWidth to add space to the left of the timeline           
      >
        <TimelineMarkers>
          <CustomMarker date={markerTime} />
        </TimelineMarkers>
        <TimelineHeaders>
          <DateHeader className="disable-zoom" />
        </TimelineHeaders>
      </Timeline>
    </div>
  );
};
export default TimelineComponent;