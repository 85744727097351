import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  DataGrid,
  GridToolbar,
  getGridStringOperators,
} from "@mui/x-data-grid";
import moment from 'moment';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, Typography, Box, FormGroup, FormControlLabel } from "@mui/material";
import CustomPaper from "../Common/CustomPaper";
import CustomCellNumRender from "./CustomCellNumRender";
import { batteryIconGreen, batteryIconRed, batteryIconOrange, toggleIcon } from "../../assets";
import Switch from '@mui/material/Switch';
import { getLiftsList, updateLiftStatus, getLiftsSummary } from "../../redux/Thunk/RoutesThunk";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Constants } from "../utils/Constants";

function LiftDataTable({ liftList, liftDataLoad, updateLiftStatus, updateStatus, getLiftList, selectedCustomerId, selectedLocationId, getLiftSummary, selectedCustomerListData, selectedLocationListData }) {
  const navigate = useNavigate();
  const [sortLiftList, setSortLiftList] = useState();
  const [liftId, setLiftId] = useState("");
  const [liftStatus, setLiftStatus] = useState("");
  const [open, setOpen] = useState(false);
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 2,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#018015" : "green",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  const routeDetailsPage = (routeId, routeName) => {
    const event = new CustomEvent(Constants.ROUTE_SELECTION_EVENT, { detail: {} });
    document.dispatchEvent(event);
    navigate('/routedetails', { state: { routeData: [{ routeId: routeId, routeName: routeName }] } })
  }

  const liftMaintenancePage = (liftId) => {
    const event = new CustomEvent(Constants.LIFT_MAINTENANCE_EVENT, { detail: {} });
    document.dispatchEvent(event);
    navigate('/liftMaintenance', { state: { liftId: liftId } })
  }

  const NumberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const filterOperators = getGridStringOperators().filter(({ value }) =>
    ["contains" /* add more over time */].includes(value)
  );
  // const [pageSize, setPageSize] = useState(10);
  const [filterModel, setFilterModel] = useState({
    items: [
      {
        field: "rating",
        operator: ">",
        value: "2.5",
      },
    ],
  });

  const columns = [
    {
      field: "status", headerName: "Status", width: 120, renderCell: (params) => {
        return (
          <>
            <Box sx={{
              '& .MuiDataGrid-cell:focus': {
                outline: ' none'
              },
            }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <AntSwitch
                      defaultChecked={params.row.status === "Out of Service" ? false : true}
                      onChange={() => handleToggle(params.row.liftId, params.row.status)}
                    />
                  }
                  label={<span style={{ color: params.row.status === "Out of Service" ? "red" : "green", fontSize: "13px" }}>{params.row.status}</span>}
                />
              </FormGroup>
              {/* <AntSwitch
                defaultChecked = {params.row.status === "Out of Service" ? false : true}
                onChange={() => handleToggle(params.row.liftId, params.row.status)}
              />
              <span style={{ color: params.row.status === "Out of Service" ? "red" : "green" }}>{params.row.status}</span> */}

              {/* <Switch
                defaultChecked={params.row.status === "Out of Service" ? false : true} color="default" size="small" onClick={() => handleToggle(params.row.liftId, params.row.status)} />
              <span style={{ color: params.row.status === "Out of Service" ? "red" : "green" }}>{params.row.status}</span> */}
            </Box>
          </>
        );
      }, filterOperators
    },
    {
      field: "liftName", headerName: "Lift Name", width: 100, renderCell: (params) => {
        return (
          <span onClick={() => liftMaintenancePage(params.row.liftId)} style={{ cursor: "pointer", color: "#2027A5", textDecoration: "underline" }}>{params.row.liftName}</span>
        )
      }, filterOperators
    },
    {
      field: "driverName", headerName: "Driver Name", width: 150, renderCell: (params) => {
        return (
          <>{params.row.driverName ? params.row.driverName : "Unassigned"}</>
        )
      },
    },
    {
      field: "routeName", headerName: "Location", width: 150, renderCell: (params) => {
        if (params.row.inDcGeofence) {
          return params.row.dc
        } else if (params.row.inDcGeofence === false && params.row.routeName !== "On Route") {
          return <span onClick={() => routeDetailsPage(params.row.routeId, params.row.routeName)} style={{ cursor: "pointer", color: "#2027A5", textDecoration: "underline" }}>{params.row.routeName}</span>
        } else {
          return params.row.routeName
        }
      }, filterOperators
    },
    {
      field: "lastReportedTime", headerName: "Last Reported Time",  align: 'center', width: 110, renderHeader: (params) => (
        <span className={"grid-column-header"}>
          Last Reported <br />Time
        </span>
      ), renderCell: (params) => {
        return <>
        <span className={"centeredPaddedRtDigits"}>
          {params.row.lastReportedTime ? moment(params.row.lastReportedTime).format('MMM D, YYYY hh:mm A') : "NA"}
        </span>
        </>;
      }, valueFormatter: (params) => {
        const valueFormatted = (params.value ? moment(params.value).format('MMM D, YYYY hh:mm A') : "NA");
        return `${valueFormatted}`;
      }, filterOperators
    },
    {
      field: "alertCount", headerName: "# of Alerts",   align: "right",width: 80, renderHeader: (params) => (
        <span className={"grid-column-header-no"}>
          # of Alerts
        </span>
      ), renderCell: (params) => {
        return (
          <>
            <span className="rtPaddedDigits-sm">{params.row.alertCount} </span>
          </>
        )
      }, filterOperators
    },
    {
      field: "batteryPercentage", headerName: "Battery Status",  align: 'right', width: 80, 
      renderHeader: (params) => (
        <span className={"grid-column-header"}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Battery <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Status
        </span>
      ), renderCell: (params) => {
        return (
          <>
          <span style={{paddingLeft:"36px"}}> 
            {(params.row.batteryPercentage || params.row.batteryPercentage === 0) ?
              params.row.batteryPercentage <= 30 ? <img src={batteryIconRed} /> : <img src={batteryIconGreen} />
              : "NA"}
            {(params.row.batteryPercentage || params.row.batteryPercentage === 0) &&
              params.row.batteryPercentage + "%"
            }
            </span>
          </>
        );
      }, filterOperators
    },
    {
      field: "temperature", headerName: "Temp(F)",   align: "right", width: 70, renderHeader: (params) => (
        <span className={"grid-column-header-no"}>
          Temp <br />
          <span className= {"centeredPaddedRtDigits-sm"} >
            (F)</span> 
        </span>

      ), renderCell: (params) => {
        return (
          <>
          <CustomCellNumRender field={params.row.temperature ? params.row.temperature : "NA"} />
          </>
        )
      }, filterOperators
    },
    {
      field: "totalTravelDistance", headerName: "Total Travel Dist (km)", width: 100, align: "right", 
      renderHeader: (params) => (
        <span className={"grid-column-header-no"}>
          Total Travel <br /> Dist (km)
        </span>
      ), renderCell: (params) => {
        const formattedValue = typeof params.row.totalTravelDistance === 'number'   ? NumberWithCommas(params.row.totalTravelDistance.toFixed(1)) : '';
        return (
          <>
            <span className="rtPaddedDigits-sm">{params.row.totalTravelDistance ?formattedValue : "NA"} </span>      
          </>
        )
      }, filterOperators
    },
    {
      field: "lastMaintenanceDate", headerName: "Last Maintenance", width: 100, align:"center",renderHeader: (params) => (
        <span className={"grid-column-header"}>
          Last <br /> Maintenance
        </span>

      ), renderCell: (params) => {

        return (
          <>
            {params.row.lastMaintenanceDate ? moment(params.row.lastMaintenanceDate).format('MMM D, YYYY') : "NA"}
          </>
        )
      }, valueFormatter: (params) => {
        const valueFormatted = (params.value ? moment(params.value).format('MMM D, YYYY') : "NA");
        return `${valueFormatted}`;
      }, filterOperators
    },
    {
      field: "maintenanceDueDate", headerName: "Maintenance Due Date", width: 120, align:"center", renderHeader: (params) => (
        <span className={"grid-column-header"} style={{textAlign:"center",paddingLeft:"10px"}}>
          Maintenance <br />Due Date
        </span>

      ),
      renderCell: (params) => {
        const dateObj = new Date(params.row.maintenanceDueDate);
        const options = {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        };
        const formattedDate = dateObj.toLocaleDateString('en-US', options);
        const start = new Date().setHours(0, 0, 0, 0);
        const end = new Date(params.row.maintenanceDueDate).setHours(0, 0, 0, 0);
        const differenceInMilliseconds = end - start;
        const differenceInDays = differenceInMilliseconds / (1000 * 3600 * 24);
        if (differenceInDays > 0 && differenceInDays < 8)
          return <>
          <span style={{align:"center"}}>
          {differenceInDays === 1 ? differenceInDays + " Day" : differenceInDays + " Days"}
          </span>
          </>;
        return <>
        <span style={{align:"center"}}>
          {params.row.maintenanceDueDate ? formattedDate : "NA"}
          </span>
          </>;
      }, valueFormatter: (params) => {
        const dateObj = new Date(params.value);
        const options = {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        };
        const formattedDate = dateObj.toLocaleDateString('en-US', options);
        const valueFormatted = (params.value ? formattedDate : "NA");
        return <span style={{align:"center"}}>`${valueFormatted}`</span>;
      }, filterOperators
    },
    {
      field: "totalPowerOnHours", headerName: "Total Power on hrs",  align: 'center', width: 100, renderHeader: (params) => (
        <span className={"grid-column-header"}>
          Total Power <br />on hrs
        </span>
      ), renderCell: (params) => {
        return (
          <>
          <span className="rtPaddedDigits-sm">{params.row.totalPowerOnHours ? NumberWithCommas(params.row.totalPowerOnHours) : "NA"}</span>
                   
          </>
        )
      }, filterOperators
    },
    {
      field: "totalWorkingHours", headerName: "Total Working hrs", align: 'center',  renderHeader: (params) => (
        <span className={"grid-column-header"}>
          Total <br /> Working hrs
        </span>
      ), renderCell: (params) => {
        return (
          <>
            <span className="rtPaddedDigits-sm">{params.row.totalWorkingHours ? NumberWithCommas(params.row.totalWorkingHours) : "NA"}</span>
          </>
        )
      }, filterOperators
    }
  ];

  let onGridResize = () => {
    console.log("Grid Resize");
  }

  const handleToggle = (liftId, liftStatus) => {
    setLiftId(liftId)
    setLiftStatus(liftStatus)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  };

  const handleUpdateStatus = (liftId, liftStatus) => {
    const liftData = {
      liftId: liftId,
      status: liftStatus === "Out of Service" ? "In Service" : "Out of Service"
    }
    updateLiftStatus(liftData);
  }

  const handleSortModelChange = (sortModel) => {
    if (sortModel.length > 0) {
      const sortedColumn = sortModel[0];
      let sortedRows;
      if (sortedColumn.field === "routeName" || sortedColumn.field === "driverName") {
        sortedRows = [...liftList].sort((a, b) =>
          String(sortedColumn.sort === "asc" ? a[sortedColumn.field] : b[sortedColumn.field]).localeCompare(
            String(sortedColumn.sort === "asc" ? b[sortedColumn.field] : a[sortedColumn.field])
          )
        );
        sortedRows.sort((a, b) =>
          a[sortedColumn.field] === b[sortedColumn.field] ?
            String(sortedColumn.sort === "asc" ? a.liftName.localeCompare(b.liftName) : b.liftName.localeCompare(a.liftName)) :
            0
        );
        sortedRows.sort((a, b) => {
          const valueA = a[sortedColumn.field];
          const valueB = b[sortedColumn.field];
          if (valueA === null && valueB === null) {
            return 0;
          } else if (valueA === null) {
            return 1;
          } else if (valueB === null) {
            return -1;
          }
        })
      } else {
        sortedRows = [...liftList].sort((a, b) => {
          const numA = parseFloat(a[sortedColumn.field]);
          const numB = parseFloat(b[sortedColumn.field]);
          const dateA = new Date(a[sortedColumn.field]);
          const dateB = new Date(b[sortedColumn.field]);
          if (!isNaN(dateA.getTime()) && !isNaN(dateB.getTime())) {
            return sortedColumn.sort === "asc" ? dateA - dateB : dateB - dateA;
          }
          if (!isNaN(numA) && !isNaN(numB)) {
            return sortedColumn.sort === "asc" ? numA - numB : numB - numA;
          }
          return String(sortedColumn.sort === "asc" ? a[sortedColumn.field] : b[sortedColumn.field]).localeCompare(
            String(sortedColumn.sort === "asc" ? b[sortedColumn.field] : a[sortedColumn.field])
          );
        });
      }
      setSortLiftList(sortedRows);
    } else {
      setSortLiftList(liftList);
    }
  };
  
  useEffect(() => {
    if(updateStatus){
      const customerLocationdata = {
        customerId: selectedCustomerListData.customerId,
        locationId: selectedLocationListData.locationId
      }
      if (selectedCustomerId && selectedLocationId) {
        getLiftSummary(customerLocationdata);
        getLiftList(customerLocationdata);
      }
      setOpen(false);
    }
  }, [updateStatus]);

  useEffect(()=>{
    setSortLiftList("")
  },[liftList])

  return (
    <CustomPaper height="100%" padding="10px">
      {open &&
        <React.Fragment>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "40px" }}>
                <img src={toggleIcon} />
              </Box>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Box style={{ paddingLeft: "40px", paddingRight: "40px" }}>
                  <Typography color="#58595B">
                    Are you sure you want to Change the Status for lift
                  </Typography>
                  <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", color: "#2027A5" }}> {liftId} </Box>
                  <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "30px", paddingBottom: "40px" }}>
                    <Button variant="contained" style={{ textTransform: 'none', maxWidth: "80px", minWidth: "80px" }} onClick={() => handleUpdateStatus(liftId, liftStatus)}>Yes</Button>
                    <Button variant="outlined" style={{ textTransform: 'none', borderColor: "#58595B", color: "#58595B", marginLeft: "20px", maxWidth: "80px", minWidth: "80px" }} onClick={handleClose}>No</Button>
                  </Box>
                </Box>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </React.Fragment>
      }
      <Typography variant="h3" sx={{ padding: "0rem 1rem 1rem 0.5rem" }}>
        Lift Details
      </Typography>
      <Box
        sx={{
          width: '100%'
        }}
      >
        <div style={{ height: 500, width: "100%" }}>
          <DataGrid
            sortingMode="server"
            onSortModelChange={handleSortModelChange} 
            loading={liftDataLoad}
            autoWidth
            rows={liftDataLoad ? [] : sortLiftList ? sortLiftList : liftList}
            columns={columns}
            getRowId={(row) => row.liftId}
            sx={{
              borderColor: "#FFF !important",
              display: "grid",
              gridTemplateRows: "auto 1f auto",
              '& .MuiDataGrid-columnHeader': {
                fontSize: "12px"
              },
              '& .MuiDataGrid-cell': {
                fontSize: "13px"
              },
              '& .MuiDataGrid-cell:focus': {
                outline: ' none'
              },
              '& .hot': {
                "textWrap": "wrap !important",
                "textAlign": "left"
              },
            }}
            slots={{
              toolbar: GridToolbar,
              noResultsOverlay: () => (
                <Box
                  sx={{
                    display: "flex", height: "100%", justifyContent: "center",
                    alignItems: "center", flexDirection: "column",
                  }}>
                  <Typography style={{ fontWeight: "400", fontSize: "16px" }}>
                    No Results Found
                  </Typography>
                  {/* <Typography variant="body1">
                  Try updating your filter choices to see results.
                </Typography> */}
                </Box>
              ),
              noRowsOverlay: () => (
                <Box
                  sx={{
                    display: "flex", height: "100%", justifyContent: "center",
                    alignItems: "center", flexDirection: "column",
                  }}>
                  <Typography style={{ fontWeight: "400", fontSize: "16px" }}>
                    No Rows
                  </Typography>
                </Box>
              ),
            }}
            getCellClassName={(params) => {
              if (params.field === 'lastReportedTime' || params.field === 'maintenanceDueDate') {
                // console.log(params.value.length)
                return 'hot';
              }
            }}
            slotProps={{ toolbar: { showQuickFilter: true, }, }}
            filterModel={filterModel}
            onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            initialState={{
              pagination: { paginationModel: { pageSize: 25 } },
            }}
            hideScrollbar={true}
            disableRowSelectionOnClick
          />
        </div>
      </Box>
    </CustomPaper>
  );
}

const mapStateToProps = (state) => {
  return {
    updateStatus: state?.Routes?.updateStatus,
    selectedCustomerListData: state?.Routes?.selectedCustomerListData,
    selectedLocationListData: state?.Routes?.selectedLocationListData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLiftList: (customerLocationdata) => dispatch(getLiftsList(customerLocationdata)),
    getLiftSummary: (customerLocationdata) => dispatch(getLiftsSummary(customerLocationdata)),
    updateLiftStatus: (liftData) => dispatch(updateLiftStatus(liftData)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LiftDataTable);